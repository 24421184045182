jQuery(function () {
    var myOffside = offside("#mobileMenu", {
        slidingElementsSelector: "body",
        buttonsSelector: ".menu-toggle, .offcanvas-close, .offcanvas-overlay",
        beforeOpen: function () {
            $(".offcanvas-overlay").fadeIn();
        },
        beforeClose: function () {
            $(".offcanvas-overlay").fadeOut();
        },
    });

    $(".product-detail-purchase").on("click", function (e) {
        e.preventDefault();
        $(".shop-online-list-wrap").fadeToggle();
    });

    productHomeSlider()

    $(".tabs").tabslet();
    $(".tabs-category").tabslet({
        container: "#productCategoryContents",
    });

    productSlider();
    productDetailSlide();
    distributionSlider();
    ZoomImg();
});

const menuMapping = new MappingListener({
    selector: ".menu-wrapper",
    mobileWrapper: ".offcanvas-wrap",
    mobileMethod: "appendTo",
    desktopWrapper: ".header-right-wrapper",
    desktopMethod: "prependTo",
    breakpoint: 1024.98,
}).watch();

function productSlider() {
    $(".product-slider").each(function (index) {
        var $this = $(this);
        if ($(this).data("slides") !== undefined) {
            var initSlide = $(this).data("slides");
        } else {
            var initSlide = 3;
        }
        $this.addClass("product-slider-" + index);
        $this.find(".swiper-prev").addClass("product-slider-prev-" + index);
        $this.find(".swiper-next").addClass("product-slider-next-" + index);
        $this
            .find(".swiper-pagination")
            .addClass("product-slider-pagination-" + index);

        var productSlider = new Swiper(
            ".product-slider-" + index + " .swiper-container",
            {
                slidesPerView: initSlide,
                spaceBetween: 30,
                navigation: {
                    prevEl: ".product-slider-prev-" + index,
                    nextEl: ".product-slider-next-" + index,
                },
                pagination: {
                    el: ".product-slider-pagination-" + index,
                    clickable: true,
                },
                breakpoints: {
                    768: {
                        slidesPerView: 2.5,
                        spaceBetween: 30,
                    },
                },
            }
        );
    });
}

function productHomeSlider() {
    $(".product-home-slider").each(function (index) {
        var $this = $(this);
        $this.addClass("product-home-slider-" + index);
        $this.find(".swiper-prev").addClass("product-home-slider-prev-" + index);
        $this.find(".swiper-next").addClass("product-home-slider-next-" + index);

        var productSlider = new Swiper(
            ".product-home-slider-" + index + " .swiper-container",
            {
                slidesPerView: 5,
                centeredSlides: true,
                spaceBetween: 30,
                initialSlide: 2,
                loop: true,
                navigation: {
                    prevEl: ".product-home-slider-prev-" + index,
                    nextEl: ".product-home-slider-next-" + index,
                },
                breakpoints: {
                    1024: {
                        slidesPerView: 3.75,
                        spaceBetween: 30,
                    },
                    768: {
                        slidesPerView: 2.5,
                        spaceBetween: 15,
                        centeredSlides: false,
                    },
                },
            }
        );
    });
}

function distributionSlider() {
    $(".distribution-slider").each(function (index) {
        var $this = $(this);
        $this.addClass("distribution-slider-" + index);
        $this
            .find(".swiper-prev")
            .addClass("distribution-slider-prev-" + index);
        $this
            .find(".swiper-next")
            .addClass("distribution-slider-next-" + index);

        var distributionSlider = new Swiper(
            ".distribution-slider-" + index + " .swiper-container",
            {
                slidesPerView: 4,
                spaceBetween: 30,
                navigation: {
                    prevEl: ".distribution-slider-prev-" + index,
                    nextEl: ".distribution-slider-next-" + index,
                },
                breakpoints: {
                    1024: {
                        slidesPerView: 3.5,
                        spaceBetween: 30,
                    },
                    768: {
                        slidesPerView: 2.5,
                        spaceBetween: 30,
                    },
                    576: {
                        slidesPerView: 2.15,
                        spaceBetween: 15,
                    },
                },
            }
        );
    });
}

var homeBannerSlider = new Swiper(".home-banner .swiper-container", {
    spaceBetween: 30,
    navigation: {
        prevEl: ".home-banner .swiper-prev",
        nextEl: ".home-banner .swiper-next",
    },
});

var partnerSlider = new Swiper(".partner-slider .swiper-container", {
    slidesPerView: 6,
    slidesPerColumn: 2,
    spaceBetween: 30,
    navigation: {
        prevEl: ".partner-slider .swiper-prev",
        nextEl: ".partner-slider .swiper-next",
    },
    breakpoints: {
        1024: {
            slidesPerView: 3.5,
            spaceBetween: 30,
        },
        768: {
            slidesPerView: 2.5,
            spaceBetween: 30,
        },
        576: {
            slidesPerView: 2.15,
            spaceBetween: 15,
        },
    },
});

var productSubnavSlider = new Swiper(".product-subnav .swiper-container", {
    slidesPerView: 5,
    spaceBetween: 15,
    navigation: {
        prevEl: ".product-subnav .swiper-prev",
        nextEl: ".product-subnav .swiper-next",
    },
    breakpoints: {
        1024: {
            slidesPerView: 4.5,
        },
        768: {
            slidesPerView: 3.5,
        },
        576: {
            slidesPerView: 2.5,
        },
    },
});

var awardSlider = new Swiper(".award-slider .swiper-container", {
    slidesPerView: 4,
    spaceBetween: 30,
    navigation: {
        prevEl: ".award-slider .swiper-prev",
        nextEl: ".award-slider .swiper-next",
    },
    breakpoints: {
        1024: {
            slidesPerView: 3.5,
        },
        768: {
            slidesPerView: 2.5,
            spaceBetween: 20,
        },
        576: {
            slidesPerView: 1.5,
            spaceBetween: 20,
        },
    },
});

function productDetailSlide() {
    var productDetailThumbnail = new Swiper(
        ".product-detail-thumbnail .swiper-container",
        {
            slidesPerView: 3,
            spaceBetween: 20,
            observer: true,
            observeParents: true,
            navigation: {
                prevEl: ".product-detail-thumbnail .swiper-prev",
                nextEl: ".product-detail-thumbnail .swiper-next",
            },
            breakpoints: {
                576: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                },
            },
        }
    );

    var productDetailImages = new Swiper(
        ".product-detail-images .swiper-container",
        {
            observer: true,
            observeParents: true,
            on: {
                slideChangeTransitionEnd: function () {
                    ZoomImg();
                },
            },
            thumbs: {
                swiper: productDetailThumbnail,
            },
        }
    );
}

function ZoomImg() {
    if ($(window).width() > 1024) {
        $(".swiper-slide-active .zoom-img").ezPlus({
            gallery: "gallery_01",
            cursor: "pointer",
            galleryActiveClass: "active",
            imageCrossfade: true,
            loadingIcon: "http://www.elevateweb.co.uk/spinner.gif",
            zoomWindowWidth: 480,
            zoomWindowHeight: 480,
            borderSize: "1",
            borderColour: "#e5e5e5",
        });
    }
}
